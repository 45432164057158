import { StatusColorEnum, StatusEnum } from '../../enums/Common';
import { Chip } from '@mui/material';
import { SubmissionStatus } from '../../types/SubmissionHistory';
import { InvertedRegistryMap } from '../../enums/Registries';

export const statusCell = (value: any) => {
  if (value) {
    switch (value) {
      case SubmissionStatus.SUCCESS: {
        let label = Object.keys(StatusEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Success)];
        label = label.replace(/([A-Z])/g, ' $1');
        label = label.charAt(0).toUpperCase() + label.slice(1);
        // @ts-ignore
        const color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Success)]];
        return <Chip label={label} size="small" variant="light" style={{ backgroundColor: color + '40', color: color }} />;
      }
      case SubmissionStatus.FAILURE: {
        let label = Object.keys(StatusEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Failure)];
        label = label.replace(/([A-Z])/g, ' $1');
        label = label.charAt(0).toUpperCase() + label.slice(1);
        // @ts-ignore
        const color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.Failure)]];
        return <Chip label={label} size="small" variant="light" style={{ backgroundColor: color + '40', color: color }} />;
      }
      case SubmissionStatus.PENDING: {
        let label = 'Submission Pending';
        // @ts-ignore
        const color = StatusColorEnum[Object.keys(StatusColorEnum)[Object.values(StatusEnum).indexOf(StatusEnum.NotProcessed)]];
        return <Chip label={label} size="small" variant="light" style={{ backgroundColor: color + '40', color: color }} />;
      }
    }
  } else {
    return '';
  }
};

export const registryCell = (value: string) => {
  if (value) {
    return InvertedRegistryMap.get(value);
  } else {
    return '';
  }
};
