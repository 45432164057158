import { Alert, Button, Card, CardContent, Dialog, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import AnimateButton from '../@extended/AnimateButton';
import CircularProgress from '@mui/material/CircularProgress';

type AbInformPopupProps = {
  isSuccessInformPopupOpen: boolean;
  domain: string;
  handlePopupClose: () => void;
};

const AbInformPopup = (props: AbInformPopupProps) => {
  const [isSuccessSnackBarOpen, setIsSuccessSnackBarOpen] = useState<boolean>(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);

  const { postData, setRefreshRequired } = useContext(DataContext);

  function handleClose() {
    props.handlePopupClose();
  }

  const handleSuccessInform = async () => {
    try {
      setLoading(true);
      if (postData) {
        const result: any = await postData('/domains/manuallyInformStatusToAb', {
          domains: [props.domain]
        });
        if (result.data.success === true) {
          setIsSuccessSnackBarOpen(true);
        } else {
          setIsErrorSnackBarOpen(true);
        }
      }
    } catch (error) {
      console.error('Error calling AB:', error);
    } finally {
      setLoading(false);
      setTimeout(() => setRefreshRequired(Date.now()), 2000);
    }
  };

  function handleOnOpenSnackBarClose() {
    props.handlePopupClose();
    setIsSuccessSnackBarOpen(false);
    setIsErrorSnackBarOpen(false);
  }

  return (
    <Fragment>
      <Dialog open={props.isSuccessInformPopupOpen} onClose={handleClose} maxWidth="xs">
        <Card>
          <CardContent>
            <Typography variant="h6" color="text.secondary">
              Are you sure to inform domain status to Autobackorder?
            </Typography>
            <Grid item xs={12} pt={3}>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <AnimateButton>
                  <Button variant="contained" type="submit" onClick={handleSuccessInform} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Yes'}
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button variant="outlined" type="submit" color="error" onClick={handleClose}>
                    No
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
      <Snackbar
        open={isSuccessSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="success" sx={{ width: '100%' }}>
          {'AB Successfully Informed'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="error" sx={{ width: '100%' }}>
          {'AB Informed Failed'}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default AbInformPopup;
