import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const CentralNIC = () => {
  //add column ids that need to show in the table
  const visibleColumns: string[] = [
    'name',
    'highPriority',
    'dropTimeFromFTP',
    'updatedDateFromWHOIS',
    'dropTime',
    'droppedAt',
    'status',
    'registeredTo',
    'drop-window',
    'success-inform',
    'ab-inform'
  ];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.CentralNIC} />;
};

export default CentralNIC;
