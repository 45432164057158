import { useMemo } from 'react';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { tableProps } from '../../types/FilteredDomain';
import ReactTable from './ReactTable';

const SortingTable = (props: tableProps) => {
  const columns = useMemo(() => props.tableColumns, [props.tableColumns]);

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable
          columns={columns}
          loading={props.loading}
          data={props.domainsList}
          hiddenColumns={props.hiddenColumns}
          totalRowsCount={props.totalRowsCount}
          getHeaderProps={(column: any) => column.getSortByToggleProps()}
          handleOnPageIndexChange={props.handleOnPageIndexChange}
          handleOnPageSizeChange={props.handleOnPageSizeChange}
          handleOnSortChange={props.handleOnSortChange}
          pageIndex={props.pageIndex}
        />
      </ScrollX>
    </MainCard>
  );
};

export default SortingTable;
