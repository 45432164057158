export enum StatusColorEnum {
  NotProcessed = '#F4AE1A', //'#F4BB44',
  Skipped = '#8E9AAF',
  Failure = '#EF4923',
  NotFound = '#6C757D',
  Success = '#67BF6B',
  Cancelled = '#DEE2E6',
  AutoRenewed = '#ADB5BD'
}
export enum StatusEnum {
  NotProcessed = 'NotProcessed', //'#F4BB44',
  Skipped = 'Skipped',
  Failure = 'Failure',
  NotFound = 'NotFound',
  Success = 'Success',
  Cancelled = 'Cancelled',
  AutoRenewed = 'Auto-Renewed'
}
export enum RegistryEnum {
  CentralNIC = 'CentralNic',
  Verisign = 'Verisign',
  SIDN = 'SIDN',
  IdentityDigital = 'Identity Digital',
  IdentityDigitalNDZ = 'Identity Digital NDZ',
  RoTLD = 'RoTLD',
  Nominet = 'Nominet',
  GoDaddy = 'GoDaddy',
  GoDaddyCO = 'GoDaddy CO'
}

export enum TypeEnum {
  Exception = 'exception',
  Critical = 'critical',
  Error = 'error',
  Warning = 'warning'
}
export enum TypeColorEnum {
  Exception = '#EF4923',
  Critical = '#EF4923',
  Error = '#F57F11',
  Warning = '#F4AE1A'
}
export enum CriticalEnum {
  Critical = 'Critical',
  NotCritical = 'Not Critical'
}
export enum Role {
  Admin = 'admin',
  ReadOnly = 'readonly',
  API = 'API'
}
export enum DateEnum {
  DateFormat = 'yyyy-MM-dd',
  DateTimeFormatLuxon = 'y-LL-dd TT'
}

export enum PriorityEnum {
  HIGH = 'High',
  LOW = 'Low'
}

export enum SuccessInformEnum {
  INFORMED = 'Informed',
  FAILED = 'Failed'
}

export enum AbInformEnum {
  NOT_REQUIRED = 'Not Required',
  INFORMED = 'Informed',
  PENDING = 'Not Informed'
}

export enum AlignOptions {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}
