export const textFieldStyle = {
  '& .MuiInputLabel-root': {
    paddingBottom: '2px',
    backgroundColor: 'transparent'
  },
  '& .MuiOutlinedInput-root': {
    '&:focus-within': {
      outline: 'none',
      boxShadow: 'none'
    }
  }
};

export const inputLabelStyle = {
  paddingBottom: '2px'
};
