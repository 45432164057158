import { usePagination, useSortBy, useTable } from 'react-table';
import { useEffect } from 'react';
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { EmptyTable, HeaderSort, TablePagination } from '../third-party/ReactTable';
import { AlignOptions } from 'enums/Common';

const ReactTable = ({
  columns,
  data,
  hiddenColumns,
  getHeaderProps,
  totalRowsCount,
  handleOnPageIndexChange,
  handleOnPageSizeChange,
  handleOnSortChange,
  loading,
  pageIndex
}: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageSize, sortBy }
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableSortRemove: true,
      // @ts-ignore
      initialState: { pageSize: 10, hiddenColumns: hiddenColumns },
      manualPagination: true
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    handleOnPageSizeChange(pageSize);
    handleOnSortChange(sortBy);
  }, [handleOnPageSizeChange, handleOnSortChange, pageSize, sortBy]);

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell
                align={column.align ?? AlignOptions.LEFT}
                {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}
              >
                <HeaderSort column={column} sort={column.isSorted} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {loading ? (
          <TableRow>
            <TableCell colSpan={24}>
              <Skeleton variant="rounded" height={550} />
            </TableCell>
          </TableRow>
        ) : page.length > 0 ? (
          page.map((row: any) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell: any) => (
                  <TableCell align={cell.column.align ?? AlignOptions.LEFT} {...cell.getCellProps([{ className: cell.column.className }])}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            );
          })
        ) : (
          <EmptyTable msg="No Data" colSpan={headerGroups[0]?.headers?.length ?? 10} />
        )}
        <TableRow
          sx={{
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          }}
        >
          <TableCell sx={{ p: 2 }} colSpan={headerGroups[0]?.headers?.length ?? 10}>
            <TablePagination
              totalRowsCount={totalRowsCount}
              handleOnPageIndexChange={handleOnPageIndexChange}
              setPageSize={setPageSize}
              pageSize={pageSize}
              dataLength={data.length}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ReactTable;
