// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //
type SearchProps = {
  handleOnSearch: (value: any) => void;
};
const ErrorSearch = (props: SearchProps) => (
  <Box>
    <FormControl fullWidth>
      <OutlinedInput
        onChange={(event) => {
          props.handleOnSearch(event.target.value.trim());
        }}
        id="header-search"
        startAdornment={
          <InputAdornment position="start" sx={{ mr: -0.5 }}>
            <SearchOutlined />
          </InputAdornment>
        }
        aria-describedby="header-search-text"
        inputProps={{
          'aria-label': 'weight'
        }}
        placeholder="Search in the Message"
      />
    </FormControl>
  </Box>
);

export default ErrorSearch;
