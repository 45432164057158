import {
  Alert,
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack
} from '@mui/material';
import TextField from '@mui/material/TextField';
import MainCard from '../MainCard';
import AnimateButton from '../@extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './add-user-form.css';
import { Fragment, useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Role } from '../../enums/Common';
import { LoadingButton } from '@mui/lab';

type AddUserFormProps = {
  isAddUserOpen: boolean;
  handleOnAddUserFormClose: () => void;
};
const AddUserForm = (props: AddUserFormProps) => {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const handleOnOpenSnackBarClose = () => {
    setIsSnackBarOpen(false);
    setIsErrorSnackBarOpen(false);
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .transform((value) => (value ? value.trim() : ''))
      .test('is-required', 'Name is required', (value) => !!value)
      .min(2, 'Name is too short')
      .max(255, 'Name is too large')
      .matches(/^[a-zA-Z\s]+$/, 'Invalid Characters')
      .required('Name is required'),
    email: yup
      .string()
      .transform((value) => (value ? value.trim() : ''))
      .test('is-required', 'Email is required', (value) => !!value)
      .email('Enter a valid email')
      .required('Email is required'),
    role: yup.string().required('Role is required')
  });
  const { postData } = useContext(DataContext);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      role: '',
      submit: null
    },
    validationSchema,
    onSubmit: async (values, { setErrors }) => {
      setIsSubmitLoading(true);
      const body = {
        name: formik.values.name,
        username: formik.values.email,
        role: formik.values.role
      };
      let response: any;
      // try {
      // @ts-ignore
      response = await postData('/addUser', body);
      // } catch (error) {
      //   console.log('error');
      // }
      if(response) {
        if (response.data) {
          if (response.data.status === 201) {
            props.handleOnAddUserFormClose();
            formik.resetForm();
            setIsSnackBarOpen(true);
          } else {
            setErrors({submit: response.data.message});
          }
        }
      }
      // else if (response.response.data) {
      //   setErrors({ submit: response.response.data.message });
      // } else {
      //   setIsErrorSnackBarOpen(true);
      // }
      setIsSubmitLoading(false);
    }
  });

  const handleClose = () => {
    props.handleOnAddUserFormClose();
    formik.resetForm();
  };
  return (
    <Fragment>
      <Dialog open={props.isAddUserOpen} onClose={handleClose} maxWidth="xs">
        <Box sx={{ p: 1, py: 1.5 }}>
          <MainCard title="ADD USER">
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="name">Name</InputLabel>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      value={formik.values.name}
                      onChange={(event) => {
                        const capitalizeValue = event.target.value
                          .toLowerCase()
                          .split(' ')
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ');
                        formik.setFieldValue('name', capitalizeValue);
                      }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="age">Role</InputLabel>
                    <FormControl sx={{ m: 1, minWidth: 120 }} error={formik.touched.role && Boolean(formik.errors.role)}>
                      <Select
                        id="role"
                        name="role"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select a role
                        </MenuItem>
                        <MenuItem value={Role.Admin}>Admin</MenuItem>
                        <MenuItem value={Role.ReadOnly}>Read Only</MenuItem>
                        <MenuItem value={Role.API}>API</MenuItem>
                      </Select>
                      {Boolean(formik.errors.role) && formik.touched.role && (
                        <FormHelperText error id="standard-weight-helper-text-email-login">
                          {' '}
                          {formik.errors.role}{' '}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    <AnimateButton>
                      <Button variant="outlined" type="button" color="error" onClick={handleClose}>
                        Cancel
                      </Button>
                    </AnimateButton>
                    <AnimateButton>
                      <LoadingButton variant="contained" type="submit" loading={isSubmitLoading}>
                        Submit
                      </LoadingButton>
                    </AnimateButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </MainCard>
        </Box>
      </Dialog>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="success" sx={{ width: '100%' }}>
          User Created Successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="error" sx={{ width: '100%' }}>
          Something Went Wrong!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default AddUserForm;
