import React, { createContext, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import useAuth from '../hooks/useAuth';
import { getToken } from '../utils/common';
import { openSnackbar } from '../store/reducers/snackbar';
import { useDispatch } from 'react-redux';

interface DataContextProps {
  fetchData: (url: string) => Promise<any | null>;
  postData: (url: string, body: any) => Promise<any | null>;
  setRefreshRequired: (time: number) => void;
}

export const DataContext = createContext<DataContextProps>({} as DataContextProps);

export const DataProvider = ({ children }: { children: React.ReactElement }) => {
  const [refreshRequired, setRefreshRequired] = useState<number>(Date.now());

  console.log('Data Context Refreshed at', refreshRequired);

  const dispatch = useDispatch();
  const { logout } = useAuth();

  const api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  api.interceptors.request.use((config) => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (config.headers && config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  });

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: 'Sorry, There was a issue on logout',
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
    }
  };

  const fetchData = async (url: string) => {
    try {
      const response = await api.get(url, {});
      return response.data;
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: 'Server Error',
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
      if (error) {
        // @ts-ignore
        if (error.response.status === 401) {
          await handleLogout();
        }
      }
    }
  };

  const postData = async (url: string, body: any) => {
    let errorMessage = 'Server Error';
    try {
      const response = await api.post(url, body);
      return response;
    } catch (error: any) {
      if (error.response) {
        errorMessage = error.response?.data?.message ?? errorMessage;
        if (error.response.status === 401) {
          await handleLogout();
        }
      }
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: errorMessage,
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
      return null;
    }
  };

  return <DataContext.Provider value={{ fetchData, postData, setRefreshRequired }}>{children}</DataContext.Provider>;
};
