import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const EURid = () => {
  const visibleColumns: string[] = ['name', 'dropTime', 'droppedAt', 'dropDelay', 'status', 'registeredTo', 'success-inform', 'ab-inform'];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.EURid} />;
};

export default EURid;
