import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { RegistrarOptionType } from '../../types/FilteredDomain';
import { textFieldStyle } from './Style';

type RegistrarFilterProps = {
  registry: string;
  registryId: string;
  endDate: number;
  startDate: number;
  handleOnRegistrarChange: (value: string) => void;
};

const RegistryFilter = (props: RegistrarFilterProps) => {
  const { postData } = useContext(DataContext);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<RegistrarOptionType[]>([]);
  const loading = open && options.length === 0;

  const handleOnRegistrarNameClicked = (value: any, newValues: any) => {
    newValues = newValues.map((newValue: any) => newValue.registeredTo);
    props.handleOnRegistrarChange(newValues);
  };
  const handleOnRegistrarName = async (event: string) => {
    const body = {
      page: 1, //page
      size: 1000, //number of rows
      filterAnd: [
        {
          key: 'registeredTo',
          operation: 'LIKE',
          value: '%' + event + '%'
        },
        {
          key: 'registryId',
          operation: '=',
          value: props.registryId
        },
        {
          key: 'dropTime',
          operation: '>=',
          value: Math.round(props.startDate * 1000)
        },
        {
          key: 'dropTime',
          operation: '<=',
          value: Math.round(props.endDate * 1000)
        }
      ],
      sort: {
        key: 'name',
        order: 'ASC'
      }
    };
    let active = true;
    let response: any;
    try {
      if (postData) {
        response = await postData('/domains/filterRegisteredTo', body);
      }
      if (response && response.data) {
        // access response data only if it is defined
        if (active) {
          setOptions([...response.data]);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return () => {
      active = false;
    };
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple
      id="asynchronous-demo"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
        handleOnRegistrarName('');
      }}
      onClose={() => {
        setOpen(false);
      }}
      clearIcon={false}
      filterOptions={(option) => option}
      isOptionEqualToValue={(option, value) => option.registeredTo === value.registeredTo}
      getOptionLabel={(option) => option.registeredTo}
      options={options}
      loading={loading}
      onChange={handleOnRegistrarNameClicked}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Registrar"
          sx={textFieldStyle}
          onChange={(event) => {
            handleOnRegistrarName(event.target.value.trim());
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};

export default RegistryFilter;
