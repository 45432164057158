import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { DateEnum } from '../../../../enums/Common';

const Clock = () => {
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [ctime, setTime] = useState(
    DateTime.now()
      .toUTC()
      .toFormat(DateEnum.DateTimeFormatLuxon + " 'UTC'")
  );

  const updateTime = () => {
    const time = DateTime.now()
      .toUTC()
      .toFormat(DateEnum.DateTimeFormatLuxon + " 'UTC'");
    setTime(time);
  };

  useEffect(() => {
    const timer = setInterval(() => updateTime(), 1000);
    return () => clearInterval(timer);
  }, []);

  return matchesMd ? (
    <h4 style={{ padding: '0 1rem 0 1rem', margin: '0', width: '100%' }}>{ctime}</h4>
  ) : (
    <h3 style={{ padding: '0 1rem 0 1rem', margin: '0', width: '100%' }}>{ctime}</h3>
  );
};
export default Clock;
