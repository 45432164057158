import { Fragment, useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import MainCard from '../../components/MainCard';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Box, Snackbar, Typography } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const UploadDroplist = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const { postData } = useContext(DataContext);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState<boolean>(false);

  const onFileChange = (event: any) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const handleOnOpenSnackBarClose = () => {
    setIsSnackBarOpen(false);
    setIsErrorSnackBarOpen(false);
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append('file', file);

    let response;
    try {
      if (postData) {
        response = await postData('/domains/uploadDropFile', formData);
      }
      if (response) {
        // @ts-ignore
        if (response.status === 201) {
          setIsSnackBarOpen(true);
        } else {
          setIsErrorSnackBarOpen(true);
        }
      }
    } catch (error) {
      console.log(error);
      setIsErrorSnackBarOpen(true);
    }
  };

  return (
    <Fragment>
      <MainCard title="Upload Droplist">
        <Box sx={{ '& button': { m: 1 } }}>
          <Typography mb={1}>{fileName}</Typography>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            onChange={onFileChange}
          >
            Select file
            <VisuallyHiddenInput type="file" />
          </Button>
          <Button variant="outlined" size="medium" onClick={onFileUpload} disabled={!file}>
            Upload
          </Button>
        </Box>
      </MainCard>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="success" sx={{ width: '100%' }}>
          File uploaded successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="error" sx={{ width: '100%' }}>
          Something Went Wrong!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default UploadDroplist;
