import { Autocomplete, TextField } from '@mui/material';
import { textFieldStyle } from './Style';

type FilterComponentProps = {
  filterName: string;
  registryId?: string;
  filterOptions: string[];
  enableFor?: string[];
  handleOnFilterChange: (value: any) => void;
};

const DropDownFilterComponent = (props: FilterComponentProps) => {
  const label = props.filterName;
  const filterOptions = props.filterOptions;
  const disabled: boolean = props.enableFor && props.registryId ? !props.enableFor.includes(props.registryId) : false;

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      id="tags-outlined"
      options={filterOptions}
      getOptionLabel={(option) => option}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} label={label} sx={textFieldStyle} />}
      clearIcon={false}
      onChange={(_event, newFilterValue) => {
        props.handleOnFilterChange(newFilterValue);
      }}
    />
  );
};
export default DropDownFilterComponent;
