// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
// import Notification from './Notification';
import Clock from './Clock';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <Clock />
      {!matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {/*<Notification />*/}
      <Profile />
    </>
  );
};

export default HeaderContent;
