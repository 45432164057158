import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { HeaderSort } from '../third-party/ReactTable';
import { useSortBy, useTable } from 'react-table';
import MainCard from '../MainCard';
import ScrollX from '../ScrollX';
import { useMemo } from 'react';
import { ResponseTableProps } from '../../types/AddDomains';

const ReactTable = ({ columns, data, getHeaderProps }: any) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: any) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <TableCell {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                <HeaderSort column={column} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell: any) => (
                <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const ResponseTable = (props: ResponseTableProps) => {
  const data = useMemo(() => props.responseData, [props.responseData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Domain',
        accessor: 'name'
      },
      {
        Header: 'Response Status',
        accessor: 'status',
        Cell: ({ value }: any) => {
          switch (value) {
            case 'Success':
            case 'Canceled Domain Renewed':
            case 'Success (Date unverified)':
              return <Chip color="success" label={value} size="small" variant="light" />;
            case 'Submission Pending':
              return <Chip color="info" label={value} size="small" variant="light" />;
            default:
              return <Chip color="error" label={value} size="small" variant="light" />;
          }
        }
      }
    ],
    []
  );

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable columns={columns} data={data} getHeaderProps={(column: any) => column.getSortByToggleProps()} />
      </ScrollX>
    </MainCard>
  );
};

export default ResponseTable;
