import { ColumnType } from './FilteredDomain';
import { DateTime } from 'luxon';
import { registryCell, statusCell } from '../pages/submissionHistory/SubmissionHistoryTableCell';
import { AlignOptions } from 'enums/Common';

export type SubmissionHistoryTableDomainsColumnsType = {
  id?: number | null;
  domain?: string | null;
  status?: string | null;
  message?: string | null;
  date?: number | null;
};

export type SubmissionHistoryTableResponseType = {
  code?: string;
  data?: { domains: SubmissionHistoryTableDomainsColumnsType[]; count: number };
};

export type FilterType = {
  key: string;
  operation: string;
  value: any;
};

export const formatDropDate = (value: any) => {
  if (typeof value === 'string') {
    value = parseInt(value);
  }
  if (value) {
    if (!isNaN(value)) {
      return DateTime.fromSeconds(value).toFormat('y-LL-dd');
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const tableColumns: ColumnType[] = [
  //belong registry - all
  {
    Header: 'Domain',
    accessor: 'domain',
    id: 'domain'
  },
  //belong registry - Verisign, identity digital
  {
    Header: 'Added Date',
    accessor: 'date',
    id: 'date',
    disableSortBy: false,
    type: 'dateTime',
    pattern: 'y-LL-dd',
    Cell: ({ value }: any) => formatDropDate(value)
  },

  //belong registry - all
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    disableSortBy: true,
    align: AlignOptions.CENTER,
    Cell: ({ value }: any) => statusCell(value)
  },
  //belong registry - verisign
  {
    Header: 'Message',
    accessor: 'message',
    id: 'message',
    disableSortBy: true
  },
  //belong registry - all
  {
    Header: 'Registry',
    accessor: 'registry',
    id: 'registry',
    disableSortBy: true,
    Cell: ({ value }: any) => registryCell(value)
  }
];

export enum SubmissionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure'
}

export enum SubmissionStatusFilter {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAILURE = 'Failure'
}

export enum RegistriesNameEnumSub {
  Verisign = 'Verisign',
  SIDN = 'SIDN',
  Nominet = 'Nominet',
  RoTLD = 'RoTLD',
  IdentityDigitalNDZ = 'Identity Digital NDZ',
  ZDNS = 'ZDNS',
  EURid = 'EURid',
  PIR = 'PIR',
  CentralNIC = 'CentralNic',
  DENIC = 'DENIC'
}
