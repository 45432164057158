import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import config from 'config';
import useAuth from '../../hooks/useAuth';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ isIcon, sx }: Props) => {
  const { isLoggedIn } = useAuth();
  return (
    <ButtonBase disableRipple component={Link} to={isLoggedIn ? '/dashboard' : config.defaultPath} sx={sx}>
      {isIcon ? <LogoIcon /> : <Logo />}
    </ButtonBase>
  );
};

export default LogoSection;
