import Search from '../../components/filters/Search';
import { Button, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import MainCard from '../../components/MainCard';
import { ColumnType, FilterType } from '../../types/FilteredDomain';
import { TLDsColumnsType, TLDSettingsTableResponseType } from '../../types/TLDSettings';
import ReactTable from '../../components/tables/ReactTable';
import { DeleteFilled, EditFilled, PlusCircleOutlined } from '@ant-design/icons';
import './TLD-settings.css';
import { DataContext } from '../../contexts/DataContext';

const TLDSettings = () => {
  const [totalRowsCount, setTotalRowsCount] = useState<number>(10);
  const [search, setSearch] = useState('');
  const [TLDList, setTLDList] = useState<TLDsColumnsType[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState([{ id: 'registry', desc: false }]);
  const [isLoadingTLDs, setIsLoadingTLDs] = useState<boolean>(true);

  const hiddenColumns: string[] = [];

  const { postData } = useContext(DataContext);

  useEffect(() => {
    setIsLoadingTLDs(true);
    const filter: FilterType[] = [];

    if (search.length > 0) {
      filter.push({
        key: 'tld',
        operation: 'LIKE',
        value: search + '%'
      });
    }

    const body = {
      page: pageIndex,
      size: pageSize,
      filterAnd: filter,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };

    const fetchDataAndSetResponse = async () => {
      let TLDSettingsTableResponse: TLDSettingsTableResponseType = {};
      // try {
      if (postData) {
        TLDSettingsTableResponse = await postData('/tld/viewAllTLDs', body);
      }
      // @ts-ignore
      setTLDList(TLDSettingsTableResponse?.data[0] ?? []);
      // @ts-ignore
      setTotalRowsCount(TLDSettingsTableResponse.data[1] ?? 0);
      setIsLoadingTLDs(false);
      // } catch (error) {
      //   console.log(error);
      // }
      // // @ts-ignore
      // if (TLDSettingsTableResponse.data) {
      //   // @ts-ignore
      //   setTLDList(TLDSettingsTableResponse?.data[0] ?? []);
      //   // @ts-ignore
      //   setTotalRowsCount(TLDSettingsTableResponse.data[1] ?? 0);
      // }
    };
    fetchDataAndSetResponse();
  }, [search, pageSize, pageIndex, sortColumn, postData]);

  const handleOnSearchChange = (value: any) => {
    setSearch(value);
  };
  const handleOnPageIndexChange = (value: any) => {
    setPageIndex(value);
  };
  const handleOnPageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const handleOnSortChange = (value: any) => {
    if (value.length > 0) {
      setSortColumn(value);
    }
  };

  const handleOnEditClick = (value: any) => {
    console.log('editingId' + value);
  };

  const handleOnDeleteClick = (value: any) => {
    console.log('deletingId' + value);
  };
  const handleOnAddClick = () => {
    console.log('click on Add');
  };

  const tableColumns: ColumnType[] = [
    {
      Header: 'Registry',
      accessor: 'registry',
      id: 'registry'
    },
    {
      Header: 'TLD',
      accessor: 'tld',
      id: 'TLD',
      disableSortBy: true
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return (
          <>
            <IconButton aria-label="delete" size="small" disabled={true} onClick={() => handleOnEditClick(value)}>
              <EditFilled className="tld-action-icons-edit" />
            </IconButton>
            <IconButton aria-label="delete" size="small" disabled={true} onClick={() => handleOnDeleteClick(value)}>
              <DeleteFilled className="tld-action-icons-delete" />
            </IconButton>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Grid container justifyContent="space-between" mb={2}>
        <Grid item md={3} xs={7}>
          <Search handleOnSearch={handleOnSearchChange} />
        </Grid>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            startIcon={<PlusCircleOutlined />}
            disabled={true}
            onClick={handleOnAddClick}
          >
            Add TLD
          </Button>
        </Grid>
      </Grid>
      {isLoadingTLDs ? (
        <Grid item xs={12} pt={2}>
          <Skeleton variant="rounded" height={400} />
        </Grid>
      ) : (
        <MainCard>
          <Typography component={'span'} variant="body2">
            <ReactTable
              columns={tableColumns}
              data={TLDList}
              hiddenColumns={hiddenColumns}
              getHeaderProps={(column: any) => column.getSortByToggleProps()}
              totalRowsCount={totalRowsCount}
              handleOnPageIndexChange={handleOnPageIndexChange}
              handleOnPageSizeChange={handleOnPageSizeChange}
              handleOnSortChange={handleOnSortChange}
            />
          </Typography>
        </MainCard>
      )}
    </>
  );
};

export default TLDSettings;
