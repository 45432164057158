import { useContext, useState, useEffect } from 'react';
import { Chip, Grid, Typography } from '@mui/material';
import MainCard from '../../components/MainCard';
import ReactTable from '../../components/tables/ReactTable';
import { DateEnum } from 'enums/Common';
import RegistryFilter from 'components/filters/errorFilters/RegistryFilter';
import TypeFilter from 'components/filters/errorFilters/TypeFilter';
import CriticalityFilter from 'components/filters/errorFilters/CriticalityFilter';
import RangePicker from '../../components/filters/RangePicker';
import ErrorSearch from 'components/filters/errorFilters/ErrorSearch';
import { useDispatch } from 'react-redux';
import { DataContext } from '../../contexts/DataContext';
import { DateTime } from 'luxon';
import { ErrorLogColumnType, FilterType, ColumnType, ErrorLogTableResponseType } from 'types/Error';
import { TypeColorEnum, TypeEnum } from 'enums/Common';
import './error.css';

export const dateTimeFormat = (value: any) => {
  if (typeof value === 'string') {
    value = parseInt(value);
  }
  if (!isNaN(value) && value !== null) {
    return DateTime.fromMillis(value / 1000).toFormat(DateEnum.DateTimeFormatLuxon);
  } else {
    return '';
  }
};

export const typeCell = (value: any) => {
  if (value) {
    let label = Object.keys(TypeEnum)[Object.values(TypeEnum).indexOf(value)];
    label = label.replace(/([A-Z])/g, ' $1');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    // @ts-ignore
    const color = TypeColorEnum[Object.keys(TypeColorEnum)[Object.values(TypeEnum).indexOf(value)]];
    return <Chip label={label} size="small" variant="light" style={{ backgroundColor: color + '40', color: color }} />;
  } else {
    return '';
  }
};
const Errorlogs = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState([{ id: 'level', desc: false }]);
  const [startDate, setStartDate] = useState(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis() - 259200000);
  const [endDate, setEndDate] = useState(DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis() + 86400000);
  const [search, setSearch] = useState('');
  const [critical, setcritical] = useState([]);
  const [registry, setregistry] = useState('');
  const [type, settype] = useState('');
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const [usersList, setUsersList] = useState<ErrorLogColumnType[]>([]);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const hiddenColumns: string[] = [];
  const registryMap = new Map([
    ['CentralNic', 'centralnic'],
    ['Verisign', 'verisign'],
    ['SIDN', 'nl'],
    ['Identity Digital', 'donuts'],
    ['Identity Digital NDZ', 'donutsndz'],
    ['RoTLD', 'rotld'],
    ['Nominet', 'uk'],
    ['GoDaddy', 'godaddy'],
    ['GoDaddy CO', 'godaddy-co']
  ]);

  const { postData } = useContext(DataContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsLoadingUsers(true);
    const filter: FilterType[] = [
      {
        key: 'timestampstart',
        operation: '>=',
        value: Math.round(startDate * 1000)
      },
      {
        key: 'timestampend',
        operation: '<=',
        value: Math.round(endDate * 1000)
      }
    ];
    if (registry.length > 0) {
      let registryArray: string[] = registry.toString().split(',');
      // @ts-ignore
      registryArray = registryArray.map((registry: string) => {
        const value = registryMap.get(registry);
        if (!!value) {
          return value;
        }
      });
      filter.push({
        key: 'registry',
        operation: 'IN',
        value: registryArray
      });
    }
    if (critical.length > 0) {
      filter.push({
        key: 'level',
        operation: 'IN',
        value: critical
      });
    }
    if (type.length > 0) {
      filter.push({
        key: 'errortype',
        operation: 'IN',
        value: type
      });
    }

    if (search.length > 0) {
      filter.push({
        key: 'message',
        operation: 'LIKE',
        value: search + '%'
      });
    }

    const body = {
      page: pageIndex,
      size: pageSize,
      filterAnd: filter,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };

    const fetchDataAndSetResponse = async () => {
      let errorTableResponse: ErrorLogTableResponseType;
      // try {
      if (postData) {
        errorTableResponse = await postData('/errorlogs', body);
      }
      // @ts-ignore
      setUsersList(errorTableResponse?.data[0] ?? []);

      // @ts-ignore
      setTotalRowsCount(errorTableResponse?.data[1] ?? 0);
      setIsLoadingUsers(false);
      // } catch (error) {
      //   console.log(error);
      // }
      // // @ts-ignore
      // if (usersTableResponse.data) {
      //   // @ts-ignore
      //   setUsersList(usersTableResponse.data[0]);
      //   // @ts-ignore
      // }
    };
    fetchDataAndSetResponse();
  }, [startDate, endDate, search, critical, registry, type, pageSize, pageIndex, sortColumn, postData, dispatch]);
  console.log(usersList);

  const handleOnDateChange = (value: any) => {
    if (!isNaN(value[0])) {
      setStartDate(value[0]);
    }
    if (!isNaN(value[1])) {
      setEndDate(value[1]);
    }
  };

  const handleOnPageIndexChange = (value: any) => {
    setPageIndex(value);
  };

  const handleOnPageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const handleOnSortChange = (value: any) => {
    if (value.length > 0) {
      setSortColumn(value);
    }
  };
  const handleOnSearchChange = (value: any) => {
    setSearch(value);
  };
  const handleOncriticalChange = (value: any) => {
    setcritical(value);
  };
  const handleOnregistryChange = (value: any) => {
    setregistry(value);
  };
  const handleOntypeChange = (value: any) => {
    settype(value);
  };
  const tableColumns: ColumnType[] = [
    {
      Header: 'REGISTRY',
      accessor: 'registry',
      id: 'registry',
      disableSortBy: true
    },
    {
      Header: 'TIME START',
      accessor: 'timestampstart',
      id: 'timestampstart',
      Cell: ({ value }: any) => dateTimeFormat(value)
    },
    {
      Header: 'TIME END',
      accessor: 'timestampend',
      id: 'timestampend',
      Cell: ({ value }: any) => dateTimeFormat(value)
    },
    {
      Header: 'ERROR',
      accessor: 'errortype',
      id: 'errortype',
      disableSortBy: true,
      Cell: ({ value }: any) => typeCell(value)
    },
    {
      Header: 'LEVEL',
      accessor: 'level',
      id: 'level',
      disableSortBy: true
    },
    {
      Header: 'MESSAGE',
      accessor: 'message',
      disableSortBy: true
    },
    {
      Header: 'ERROR DESCRIPTION',
      accessor: 'errorwarningexception',
      id: 'errorwarningexception',
      disableSortBy: true
    },

    {
      Header: 'COUNT',
      accessor: 'count',
      id: 'count'
    }
  ];

  return (
    <>
      <Grid container direction="row-reverse" mb={2}>
        <Grid item md={12}>
          <Grid container direction="row-reverse" justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item md={4} xs={12}>
              <RangePicker startDate={startDate} endDate={endDate} handleOnDateChange={handleOnDateChange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item md={3} xs={12}>
              <ErrorSearch handleOnSearch={handleOnSearchChange} />
            </Grid>
            <Grid item md={3} xs={12}>
              <RegistryFilter handleOnRegistryChange={handleOnregistryChange} />
            </Grid>
            <Grid item md={3} xs={12}>
              <TypeFilter handleOnTypeChange={handleOntypeChange} />
            </Grid>
            <Grid item md={3} xs={12}>
              <CriticalityFilter handleOnCriticalityChange={handleOncriticalChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MainCard className="users-main-card">
        <Typography component={'span'} variant="body2">
          <ReactTable
            columns={tableColumns}
            data={usersList}
            loading={isLoadingUsers}
            hiddenColumns={hiddenColumns}
            getHeaderProps={(column: any) => column.getSortByToggleProps()}
            totalRowsCount={totalRowsCount}
            handleOnPageIndexChange={handleOnPageIndexChange}
            handleOnPageSizeChange={handleOnPageSizeChange}
            handleOnSortChange={handleOnSortChange}
          />
        </Typography>
      </MainCard>
    </>
  );
};

export default Errorlogs;
