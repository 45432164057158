import React, { useContext, useState } from 'react';
import { ConfigContext } from '../../../../../contexts/ConfigContext';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { BgColorsOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const { onChangeMode } = useContext(ConfigContext);
  const theme = useTheme();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleChangeThemeClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
    if (theme.palette.mode === 'light') {
      onChangeMode('dark');
    } else {
      onChangeMode('light');
    }
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleChangeThemeClick(event, 0)}
      >
        <ListItemIcon>
          <BgColorsOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Theme" />
      </ListItemButton>
    </List>
  );
};

export default SettingTab;
