import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import { AbInformEnum, StatusColorEnum, StatusEnum } from 'enums/Common';
import AbInformPopup from 'components/popup/AbInformPopup';
import { getClickableStyle } from './FilteredDomainsTableColumns';

export type ABInformCellProps = {
  domainName: string;
  domainStatus: StatusEnum;
  isInformedFromAB: boolean;
  isInformedStatusToAB: boolean;
};

const informPossibleStatus: StatusEnum[] = Object.values(StatusEnum).filter(
  (value) => value !== StatusEnum.NotProcessed && value !== StatusEnum.Cancelled
);

const AbInformCell = ({ domainName, domainStatus, isInformedFromAB, isInformedStatusToAB }: ABInformCellProps) => {
  const [scenario, setScenario] = useState<AbInformEnum>(AbInformEnum.NOT_REQUIRED);
  const [color, setColor] = useState<StatusColorEnum>(StatusColorEnum.NotProcessed);
  const [isInformValid, setIsInformValid] = useState<boolean>(false);
  const [isInformPopupOpen, setIsInformPopupOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    if (isInformedFromAB) {
      if (isInformedStatusToAB) {
        setScenario(AbInformEnum.INFORMED);
        setColor(StatusColorEnum.Success);
      } else {
        setScenario(AbInformEnum.PENDING);
      }
    } else {
      setScenario(AbInformEnum.NOT_REQUIRED);
      setColor(StatusColorEnum.NotProcessed);
    }

    if (isInformedFromAB && !isInformedStatusToAB && informPossibleStatus.includes(domainStatus)) {
      setIsInformValid(true);
    }
  }, [isInformedFromAB, isInformedStatusToAB, domainStatus]);

  return (
    <div>
      <AbInformPopup
        isSuccessInformPopupOpen={isInformPopupOpen}
        domain={domainName}
        handlePopupClose={() => setIsInformPopupOpen(false)}
      />
      {scenario === AbInformEnum.NOT_REQUIRED ? null : (
        <Chip
          onClick={() => {
            if (isInformValid) {
              setIsInformPopupOpen(true);
            }
          }}
          label={isHovering ? 'Inform now' : scenario}
          size="small"
          variant={isInformValid ? 'outlined' : 'light'}
          sx={getClickableStyle(isInformValid, color)}
          onMouseEnter={() => {
            if (isInformValid) {
              setIsHovering(true);
            }
          }}
          onMouseLeave={() => {
            setIsHovering(false);
          }}
        />
      )}
    </div>
  );
};

export default AbInformCell;
