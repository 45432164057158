import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const IdentityDigitalNDZ = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.IdentityDigitalNDZ}>
      <AddDomainsContent registryId={RegistriesIdEnum.IdentityDigitalNDZ} registryName={RegistriesNameEnum.IdentityDigitalNDZ} url={url} />
    </MainCard>
  );
};

export default IdentityDigitalNDZ;
