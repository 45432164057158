import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { TypeEnum } from '../../../enums/Common';
import { inputLabelStyle } from '../Style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(option: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

type TypeFilterProps = {
  handleOnTypeChange: (value: string[]) => void;
};
const TypeFilter = (props: TypeFilterProps) => {
  const theme = useTheme();
  const [type, setType] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof type>) => {
    let {
      target: { value }
    } = event;
    setType(typeof value === 'string' ? value.split(',') : value);
    // if (value.length === 0) {
    //   value = Object.keys(TypeEnum).toString().split(',');
    // }
    props.handleOnTypeChange(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel sx={inputLabelStyle} id="demo-multiple-chip-label">
        Error Type
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={type}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Error Type" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value.replace(/([A-Z])/g, ' $1')} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {Object.keys(TypeEnum).map((option: string) => (
          // @ts-ignore
          <MenuItem key={option} value={TypeEnum[option]} style={getStyles(option, type, theme)}>
            {option.replace(/([A-Z])/g, ' $1')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TypeFilter;
