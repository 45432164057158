import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import TLDSettings from '../pages/TLD-settings/TLDSettings';
import Users from '../pages/users/Users';
import AddVerisign from '../pages/add-domains/Verisign';
import AddSIDN from '../pages/add-domains/SIDN';
import AddNominet from '../pages/add-domains/Nominet';
import AddRoTLD from '../pages/add-domains/RoTLD';
import AddPIR from '../pages/add-domains/PIR';
import CentralNIC from '../pages/filtered-domains/CentralNIC';
import SIDN from '../pages/filtered-domains/SIDN';
import Verisign from '../pages/filtered-domains/Verisign';
import IdentityDigital from '../pages/filtered-domains/IdentityDigital';
import RoTLD from '../pages/filtered-domains/RoTLD';
import Nominet from '../pages/filtered-domains/Nominet';
import GoDaddy from '../pages/filtered-domains/GoDaddy';
import Errorlogs from 'pages/errorlog/Errorlog';
import IdentityDigitalNDZ from '../pages/filtered-domains/IdentityDigitalNDZ';
import AddIdentityDigitalNDZ from '../pages/add-domains/IdentityDigitalNDZ';
import ZDNS from '../pages/filtered-domains/ZDNS';
import AddZDNS from '../pages/add-domains/ZDNS';
import EURid from '../pages/filtered-domains/EURid';
import AddEURid from '../pages/add-domains/EURid';
import PIR from 'pages/filtered-domains/PIR';
import DE from '../pages/filtered-domains/DE';
import AddDE from '../pages/add-domains/DE';
import UploadDroplist from '../pages/fileUpload/uploadDroplist';
import AddCentralNic from '../pages/add-domains/CentralNIC';
import SubmissionHistoryPage from '../pages/submissionHistory/SubmissionHistoryPage';
// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const Dashboard = Loadable(lazy(() => import('pages/dashboard/Dashboard')));

const AuthResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'tld-settings',
          element: <TLDSettings />
        },
        {
          path: 'filtered-domains',
          children: [
            {
              path: 'cnic',
              element: <CentralNIC />
            },
            {
              path: 'verisign',
              element: <Verisign />
            },
            {
              path: 'sidn',
              element: <SIDN />
            },
            {
              path: 'identity-digital',
              element: <IdentityDigital />
            },
            {
              path: 'identity-digitalNDZ',
              element: <IdentityDigitalNDZ />
            },
            {
              path: 'rotld',
              element: <RoTLD />
            },
            {
              path: 'nominet',
              element: <Nominet />
            },
            {
              path: 'godaddy',
              element: <GoDaddy />
            },
            {
              path: 'zdns',
              element: <ZDNS />
            },
            {
              path: 'eu',
              element: <EURid />
            },
            {
              path: 'pir',
              element: <PIR />
            },
            {
              path: 'de',
              element: <DE />
            }
          ]
        },
        {
          path: 'users',
          element: <Users />
        },
        {
          path: 'errorlog',
          element: <Errorlogs />
        },
        {
          path: 'uploadDroplist',
          element: <UploadDroplist />
        },
        {
          path: 'submissionHistory',
          element: <SubmissionHistoryPage />
        },
        {
          path: 'add-domains',
          children: [
            {
              path: 'verisign',
              element: <AddVerisign />
            },
            {
              path: 'sidn',
              element: <AddSIDN />
            },
            {
              path: 'nominet',
              element: <AddNominet />
            },
            {
              path: 'rotld',
              element: <AddRoTLD />
            },
            {
              path: 'identityDigitalNDZ',
              element: <AddIdentityDigitalNDZ />
            },
            {
              path: 'zdns',
              element: <AddZDNS />
            },
            {
              path: 'eu',
              element: <AddEURid />
            },
            {
              path: 'pir',
              element: <AddPIR />
            },
            {
              path: 'de',
              element: <AddDE />
            },
            {
              path: 'centralnic',
              element: <AddCentralNic />
            }
          ]
        }
      ]
    },
    {
      path: 'reset-password',
      element: <AuthResetPassword />
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
