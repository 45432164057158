import MainCard from 'components/MainCard';
import AddDomainsContent, { ConfigsEnum } from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const Nominet = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.Nominet}>
      <AddDomainsContent
        registryId={RegistriesIdEnum.Nominet}
        registryName={RegistriesNameEnum.Nominet}
        url={url}
        config={ConfigsEnum.MANUAL_PRIORITY}
      />
    </MainCard>
  );
};

export default Nominet;
