import { Autocomplete, TextField } from '@mui/material';
import { textFieldStyle } from './Style';

type FilterComponentProps = {
  filterName: string;
  filterOptions: string[];
  handleOnFilterChange: (value: any) => void;
};

const SingleSelectDropDown = (props: FilterComponentProps) => {
  const label = props.filterName;
  const filterOptions = props.filterOptions;

  return (
    <Autocomplete
      disablePortal
      freeSolo={false}
      options={filterOptions}
      renderInput={(params: any) => <TextField {...params} sx={textFieldStyle} label={label} />}
      onChange={(_event, newFilterValue) => {
        props.handleOnFilterChange(newFilterValue);
      }}
    />
  );
};
export default SingleSelectDropDown;
