import { AlignOptions } from 'enums/Common';

export enum AttemptColorEnum {
  NotAttempt = 'error',
  FirstAttempt = 'success',
  SecondAttempt = 'primary',
  IncorrectPrediction = 'warning'
}

export type ColumnType = {
  Header: string;
  accessor?: string;
  id?: string;
  align?: AlignOptions;
  disableSortBy?: boolean;
  type?: string;
  pattern?: string;
  Cell?: (props: any) => React.ReactNode;
};

export type FilterType = {
  key: string;
  operation: string;
  value: any;
};

export type DomainTableResponseType = {
  code?: string;
  data?: { domains: FilteredDomainsColumnsType[]; count: number };
};
export interface tableProps {
  tableColumns: ColumnType[];
  hiddenColumns: string[];
  totalRowsCount: number;
  domainsList: FilteredDomainsColumnsType[] | undefined;
  loading: boolean;
  pageIndex: number;

  handleOnPageIndexChange: (value: any) => void;
  handleOnPageSizeChange: (value: any) => void;

  handleOnSortChange: (value: any) => void;
}

export type FilteredDomainsContentProps = {
  visibleColumns: string[];

  registryId: string;
};

export type FilteredDomainsColumnsType = {
  availableReceivedAt?: Date | null;
  checkCount?: number;
  createdBy?: number | null;
  domainInfoCount?: number | null;
  dropDelay?: number | null;
  dropTime?: number | null;
  dropTimeFromFTP?: number | null;
  droppedAt?: number | null;
  highPriority?: boolean | null;
  id?: number | null;
  initialBurstCount?: number | null;
  name?: string | null;
  purgeBurstCount?: number | null;
  purgedAt?: number | null;
  registeredTo?: string | null;
  status?: string | null;
  updatedDateFromWHOIS?: number | null;
  withinDropWindow?: number | null;
  dropDate?: string | null;
  actualCreateTime?: number | null;
  attempt?: string | null;
  createCount?: number | null;
  sentAtEpoch?: number | null;
  receivedAtEpoch?: number | null;
  applicationCreateTime?: number | null;
  dropZoneOpenTime?: number | null;
  successAttempt?: number | null;
  worker?: number | null;
  actualDropTime?: number | null;
  isFirstAttempt?: boolean | null;
  isSecondAttempt?: boolean | null;
};

export type RegistrarFilterResponseType = {
  data: RegistrarOptionType[];
};

export type RegistrarOptionType = {
  registeredTo: string;
};

export enum CalType {
  ADD = '+',
  SUB = '-',
  MULT = '*',
  DIV = '/'
}

export type Calculation = {
  value1: string;
  value2: string | Calculation | null | undefined;
  calType: CalType;
  columnName: string;
};

export type ColumnNames = {
  key: string;
  header: string;
  convertTo?: string;
  pattern?: string;
};
