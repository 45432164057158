import { Button, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import MainCard from '../../components/MainCard';
import { ColumnType } from '../../types/FilteredDomain';
import ReactTable from '../../components/tables/ReactTable';
import { PlusCircleOutlined } from '@ant-design/icons';
import { UsersColumnsType, UsersTableResponseType } from '../../types/Users';
import { DataContext } from '../../contexts/DataContext';
import './users.css';
import AddUserForm from '../../components/forms/AddUserForm';

const TLDSettings = () => {
  const [totalRowsCount, setTotalRowsCount] = useState<number>(10);
  const [usersList, setUsersList] = useState<UsersColumnsType[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState([{ id: 'name', desc: false }]);
  const [isAddUserOpen, setIsAddUserOpen] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  const hiddenColumns: string[] = [];

  const { postData } = useContext(DataContext);

  useEffect(() => {
    setIsLoadingUsers(true);
    const body = {
      page: pageIndex,
      size: pageSize,
      sort: {
        key: sortColumn[0].id,
        order: sortColumn[0].desc ? 'DESC' : 'ASC'
      }
    };

    const fetchDataAndSetResponse = async () => {
      let usersTableResponse: UsersTableResponseType;
      // try {
      if (postData) {
        usersTableResponse = await postData('/viewAllUsers', body);
      }
      // @ts-ignore
      setUsersList(usersTableResponse?.data[0] ?? []);
      // @ts-ignore
      setTotalRowsCount(usersTableResponse?.data[1] ?? 0);
      setIsLoadingUsers(false);
      // } catch (error) {
      //   console.log(error);
      // }
      // // @ts-ignore
      // if (usersTableResponse.data) {
      //   // @ts-ignore
      //   setUsersList(usersTableResponse.data[0]);
      //   // @ts-ignore
      // }
    };
    fetchDataAndSetResponse();
  }, [pageSize, pageIndex, sortColumn, postData]);

  const handleOnPageIndexChange = (value: any) => {
    setPageIndex(value);
  };
  const handleOnPageSizeChange = (value: any) => {
    setPageSize(value);
  };

  const handleOnSortChange = (value: any) => {
    if (value.length > 0) {
      setSortColumn(value);
    }
  };

  const handleOnAddUserClick = () => {
    setIsAddUserOpen(true);
  };
  const handleOnAddUserFormClose = () => {
    setIsAddUserOpen(false);
  };

  const tableColumns: ColumnType[] = [
    {
      Header: 'NAME',
      accessor: 'name',
      id: 'name'
    },
    {
      Header: 'EMAIL',
      accessor: 'userName',
      id: 'userName'
    },
    {
      Header: 'ROLE',
      id: 'roles',
      accessor: 'roles'
    }
  ];

  return (
    <>
      <Grid container direction="row-reverse" mb={2}>
        <Grid item>
          <Button
            size="medium"
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            startIcon={<PlusCircleOutlined />}
            onClick={handleOnAddUserClick}
          >
            ADD USERS
          </Button>
        </Grid>
      </Grid>
      <MainCard className="users-main-card">
        <Typography component={'span'} variant="body2">
          <ReactTable
            columns={tableColumns}
            data={usersList}
            loading={isLoadingUsers}
            hiddenColumns={hiddenColumns}
            getHeaderProps={(column: any) => column.getSortByToggleProps()}
            totalRowsCount={totalRowsCount}
            handleOnPageIndexChange={handleOnPageIndexChange}
            handleOnPageSizeChange={handleOnPageSizeChange}
            handleOnSortChange={handleOnSortChange}
          />
        </Typography>
      </MainCard>
      <Grid container>
        <AddUserForm isAddUserOpen={isAddUserOpen} handleOnAddUserFormClose={handleOnAddUserFormClose} />
      </Grid>
    </>
  );
};

export default TLDSettings;
