// return the user data from the session storage
import { UserProfile } from '../types/auth';

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('loggedTime');
};

// set the token and user from the session storage
export const setUserSession = (token: string, loggedTime: Date, user: UserProfile) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('loggedTime', String(loggedTime));
  sessionStorage.setItem(
    'user',
    JSON.stringify({
      id: user.id,
      name: user.name,
      username: user.email,
      role: user.role
    })
  );
};

export const setRememberMeStore = (userName: string) => {
  localStorage.setItem('username', userName);
};
