import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const Nominet = () => {
  //add column ids that need to show the table
  const visibleColumns: string[] = [
    'name',
    'highPriority',
    'dropTime',
    'dropTimeNominet',
    'successAttempt',
    'status',
    'registeredTo',
    'success-inform',
    'ab-inform'
  ];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.Nominet} />;
};

export default Nominet;
