import NameRiderLogo from './../../assets/images/icons/nameriderlogo.png';
import NameRiderLogoLight from './../../assets/images/icons/nameriderlogolight.png';
import { useTheme } from '@mui/material/styles';
import { Fragment } from 'react';

const LogoMain = () => {
  const theme = useTheme();
  return (
    <Fragment>
      {theme.palette.mode === 'light' ? (
        <img src={NameRiderLogo} height="35" alt={'LOGO'} width="85" />
      ) : (
        <img src={NameRiderLogoLight} height="35" alt={'LOGO'} />
      )}
    </Fragment>
  );
};

export default LogoMain;
